const menuList = [
  {
    title: '用户管理',
    key: '/management/user',
  },
  {
    title: '新闻管理',
    key: '/management/articles',
  },
  {
    title: '通知管理',
    key: '/management/notification',
  },
  {
    title: '视频管理',
    key: '/management/videos',
  },
  {
    title: '镜像仓库管理',
    key: '/management/software',
  },
  {
    title: '应用配置管理',
    key: '/management/yaml'
  },
  {
    title: '资源管理',
    key: '/management/resourceStatistics'
  },
  {
    title: '系统设置',
    key: '/management/servicemanage'
  },
  {
    title: '模型管理',
    key: '/management/aiexperience'
  },
  {
    title: '白名单管理',
    key: '/management/whitelist'
  }
]
export default menuList

