import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom';
import { Spin, Layout, message, Card, Empty } from 'antd';
import axios from 'axios'
import Nettrix from '@assets/img/nettrix_white.jpg'
import { unauthorized } from '@/utils/session';

const AIExperience = () => {
  const history = useHistory()
  const [projectList, setProjectList] = useState([])
  const [loading, setLoading] = useState(false)
  const [useable, setUseable] = useState(false)    //模型是否可以使用
  const [buttonHaveToken, setButtonHaveToken] = useState(false)    //查看更多按钮是否置为灰色
  // const [applyUseModal, setApplyUseModal] = useState(false)
  // const [currentModelId, setCurrentModelId] = useState('')

  // 获取项目列表
  const getProjectList = async()=>{
    setLoading(true)
    await axios({
      method: 'get',
      url: '/api1/api/v1/ai_experience/',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if(response.status===200){
        setLoading(false)
        const res = response.data.data
        setProjectList(res)
      }
    })
    .catch(error => {
      if(error.response){
        const res = error.response
        if(res.status===400){
          message.error(res.data.message)
        }else if(res.status===401){
          message.error(res.data.message)
          unauthorized();
        }else if(res.status===500){
          message.error(res.data.message)
        }
      }
    })
  }
  // 获取模型权限
  const getModelAuthorized = async()=>{
    const token = localStorage.getItem('token')
    await axios({
      method: 'get',
      url: '/api1/api/v1/ai_experience/request/valid',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if(response.status===200){
        const res = response.data
        if(res.usable){
          setButtonHaveToken(true)
        }else {
          setButtonHaveToken(false)
        }
        setUseable(res.usable)
      }
    })
    .catch(error => {
      if(error.response){
        const res = error.response
        if(res.status===400){
          message.error(res.data.message)
        }else if(res.status===401){
          message.error(res.data.message)
          unauthorized();
        }else if(res.status===500){
          message.error(res.data.message)
        }
      }
    })
  }
  // 模型使用权限验证
  // const validateAiExperienceRequest = async(model_id)=>{
  //   const token = localStorage.getItem('token')
  //   await axios({
  //     method: 'get',
  //     url: `/api1/api/v1/ai_experience/request/${model_id}`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`
  //     }
  //   })
  //   .then(response => {
  //     const res = response.data
  //     if(response.status===200){
  //       if(res.usable){
  //        history.push(`/aiexperiencemodel/${model_id}`)
  //       }else {
  //         // setApplyUseModal(true)
  //       }
  //     }
  //   })
  //   .catch(error => {
  //     if(error.response){
  //       const res = error.response
  //       if(res.status===400){
  //         message.error(res.data.message)
  //       }else if(res.status===401){
  //         message.error(res.data.message)
  //         unauthorized();
  //       }else if(res.status===500){
  //         message.error(res.data.message)
  //       }
  //     }
  //   })
  // }
  // 模型申请使用权限
  // const applyUseAiExperience = async()=>{
  //   const token = localStorage.getItem('token')
  //   await axios({
  //     method: 'post',
  //     url: `/api1/api/v1/ai_experience/request/${currentModelId}`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`
  //     },
  //   })
  //   .then(response => {
  //     const res = response.data
  //     if(response.status===200){
  //       message.success(res.message)
  //       setApplyUseModal(false)
  //     }
  //   })
  //   .catch(error => {
  //     if(error.response){
  //       const res = error.response
  //       if(res.status===400){
  //         message.error(res.data.message)
  //       }else if(res.status===401){
  //         console.log('没有权限');
  //         unauthorized();
  //       }else if(res.status===500){
  //         console.log('Internal server error');
  //       }
  //     }
  //     setApplyUseModal(false)
  //   })
  // }
  // const handleCancel = ()=>{
  //  setApplyUseModal(false)
  // }
  // 项目详情
  // const viewMoreDetial = async(model_id)=>{
  //   setCurrentModelId(model_id)
  //   if(localStorage.getItem('token')){
  //     validateAiExperienceRequest(model_id)
  //   }else {
  //     history.push('/sign/login')
  //   }
  // }
  const viewMoreDetial = async(model_id)=>{
    history.push(`/aiexperiencemodel/${model_id}`)
  }
  const goBackLogin = ()=>{
    history.push('/sign/login')
  }

  useEffect(()=>{
    getProjectList()
    if(localStorage.getItem('token')){
      getModelAuthorized()
    }else {
      setButtonHaveToken(true)
    }
  },[])
  return (
    <div className='bg-white nologinhome overflow-x-hidden'>
      <div style={{position: 'fixed',zIndex: 1, width: '100%', height: '66px'}} className="bg-white border-b border-gray-300">
        <div className='2xl:container mx-auto px-1 2xl:px-0'>
          <Link to='/'>
            <img src={Nettrix} alt='logo' className='h-12 mt-2.5' />
          </Link>
        </div>
      </div>
      <div className='2xl:container mx-auto overflow-x-hidden'>
        {
          projectList?.length > 0
          ?
          <Spin spinning={loading} tip="数据加载中，请稍候..." size='large' className='mt-40'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-16 px-8 lg:px-16 py-8 my-16'>
              {
                projectList?.map((item)=>(
                  <div key={item.id}>
                    <div 
                      className="flex items-stretch flex-col bg-white border border-gray-200 rounded-lg shadow sm:flex-row hover:bg-gray-100"
                    >
                      <img 
                        className="object-fill w-full sm:w-1/2 rounded-l-lg h-72" 
                        src={item.icon} 
                        alt="repo_image"
                      />
                      <div className="flex flex-col w-full sm:w-1/2 gap-4 px-4 leading-normal sm:mt-0 py-4">
                        {/* 标题和Tag */}
                        <div className='flex-none space-y-3'>
                          <h5 className="text-2xl font-bold tracking-tight text-gray-900 ">
                            {item.name}
                          </h5>
                          <div className='mt-2 flex flex-row flex-wrap gap-2'>
                            {
                              item?.keywords?.map((keyword, index)=>(
                                <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-blue-500" key={index}>
                                  {keyword}
                                </span>
                              ))
                            }
                          </div>
                        </div>
                        <div className='grow text-sm text-justify leading-6'>
                          {item.desc}
                        </div> 
                        <div className='flex justify-end flex-none h-8'>
                          {
                            useable === true && buttonHaveToken === true
                            ?
                            <button 
                              className="btn btn-sm btn-outline bg-blue-600 border-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white"
                              onClick={() => viewMoreDetial(item.id)}
                            >
                              查看更多
                              <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                              </svg>
                            </button>
                            :
                            useable === false && buttonHaveToken === true
                            ?
                            <button 
                              className="btn btn-sm btn-outline bg-blue-600 border-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white"
                              onClick={goBackLogin}
                            >
                              查看更多
                              <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                              </svg>
                            </button>
                            :
                            <button 
                              className="btn btn-sm btn-active btn-ghost text-white cursor-not-allowed"
                            >
                              查看更多
                              <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                              </svg>
                            </button> 

                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </Spin>
          :
          <Empty style={{ marginTop: 240}}/>
        }
      </div>
      {/* 申请试用的Modal */}
      {/* <Modal 
        title='模型申请'
        visible={applyUseModal}
        onOk={applyUseAiExperience}
        onCancel={handleCancel}
        okText="确认"
        cancelText="取消"
      >
        <span className='text-2xl flex items-center justify-center'>是否要申请试用模型？</span>
      </Modal> */}
    </div>
  )
}

export default AIExperience