/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Dropdown, Menu, Modal, message } from 'antd';
import { UserOutlined, FormOutlined, PoweroffOutlined } from '@ant-design/icons';
import axios from 'axios';
import qs from 'qs';
import AppHeader from '@components/AppHeader';
import AppContent from '@components/AppContent';
import { logout, unauthorized } from '@/utils/session';
import LoadableComponent from '@/utils/LoadableComponent'
import Nettrix from '@assets/img/nettrix_white.jpg'
import MyAccount from '@assets/img/myAccount.png'
import menuList from '@/config/menuConfig';
import './index.less';

const { Header, Content, Footer } = Layout;

const EditInfoModal = LoadableComponent(()=>import('./editInfoModal'));
const EditPasswordModal = LoadableComponent(()=>import('./editPasswordModal'));
export default class Admin extends Component {
  state = {
    infoVisible: false,
    passwordVisible: false,
    user_role_id: '',
    roles: []
  }
  onLogout = ()=>{
    const data = qs.stringify({});
    const token = localStorage.getItem('token');
    Modal.confirm({
      title: "注销",
      content: "确定要退出系统吗?",
      okText: "确定",
      cancelText: "取消",
      onOk: async () => {
        await axios({
          method: 'post',
          url: '/api1/api/v1/auth/logout',
          data: data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          if(response.status===200){
            message.success('退出成功');
            localStorage.removeItem('token');
            logout();
            this.props.history.replace('/sign/login');
          }
        })
        .catch(error => {
          if(error.response){
            const res = error.response
            if(res.status===400){
              console.log('服务器验证失败');
            }else if(res.status===401){
              message.error('Token无效或者过期,请重新登录');
              unauthorized();
            }else {
              message.error('服务器错误，请稍后再试')
            }
          }else {
            console.log(error.message)
          }
        })
      },
    });
  }
  toggleInfoVisible = (visible)=>{
    this.setState({
      infoVisible: visible
    })
  }
  togglePasswordVisible = (visible)=>{
    this.setState({
      passwordVisible: visible
    })
  }
  getUserInfo = async()=>{
    const token = localStorage.getItem('token');
    await axios({
      method: 'get',
      url: '/api1/api/v1/auth/user',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      if(response.status===200){
        const res = response.data
        this.setState({
          user_role_id: res.role_id
        })
      }
    })
    .catch(error => {
      if(error.response){
        const res = error.response
        if(res.status===400){
          console.log('服务器验证失败')
        }else {
          message.error('Token无效或者过期,请重新登录');
          unauthorized();
        }
      }
    })
  }
  /** 获取角色 */
  getRoles = async()=> {
    const token = localStorage.getItem('token');
    await axios({
      method: 'get',
      url: '/api1/api/v1/admin/role/list',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response=> {
      if(response.status===200){
        const res = response.data
        this.setState({
          roles: res
        })
      }
    })
    .catch(error=> {
      if(error.response){
        const res = error.response
        if(res.status===400){
          console.log('服务器验证失败');
        }else if(res.status===401){
          message.error('token过期或者无效，请重新登录');
          unauthorized();
        }else {
          console.log('服务器错误');
        }
      }
    })
  }
  UNSAFE_componentWillMount() {
    this.getUserInfo();
    this.getRoles();
  }
  componentDidMount = ()=>{
    const scrollPosition = localStorage.getItem("scrollPosition");
    setTimeout(() => document.getElementById('gpufz_content').scrollTo(0, parseInt(scrollPosition)), 200);
  }

  hasAuth = (item) => {
    const { key, isPublic } = item
    const { user_role_id, roles } = this.state;
    if(roles.length!==0){
      const userAuth = roles.filter((items)=>{
        return user_role_id===items.role_id
      })
      if(userAuth.length!==0){
        if(isPublic || userAuth[0]?.menus?.indexOf(key)!==-1){
          return true
        }else if(item.children){
          return !!item.children.find(child => userAuth[0].menus.indexOf(child.key)!==-1)
        }
      }
    }
    return false
  }
  getMenuNodes = (menuList)=>{
    const path = this.props.location.pathname
    return menuList.reduce((pre,item)=>{
      if(this.hasAuth(item)){
        if(!item.children){
          pre.push((
            <Menu.Item key={item.key}>
              <Link to={item.key}>
                <span>{item.title}</span>
              </Link>
            </Menu.Item>
          ))
        }else {
          const cItem = item.children.find(cItem => path.indexOf(cItem.key)===0)
          if(cItem){
            this.openKey = item.key
          }
          pre.push((
            <Menu.SubMenu
              key={item.key}
              title={
                <span>{item.title}</span>
              }
            >
              {
                this.getMenuNodes(item.children)
              }
            </Menu.SubMenu>
          ))
        }
      }
      return pre
    },[])
  }
  
  aiExperience = ()=>{
    this.props.history.replace('/aiexperience');
  }
  resourceExperience = ()=>{
    this.props.history.replace('/resource');
  }
  render() {
    const { infoVisible, passwordVisible } = this.state
    const menu = (
      <Menu>
        <Menu.ItemGroup title='用户中心'>
          <Menu.Item key='editInfoModal' onClick={()=>this.toggleInfoVisible(true)}>
            <UserOutlined style={{ marginRight:7 }}/>
            修改个人信息
          </Menu.Item>
          <Menu.Item key='editPasswordModal' onClick={()=>this.togglePasswordVisible(true)}>
            <FormOutlined style={{ marginRight:7 }}/>
            修改密码
          </Menu.Item>
          <Menu.Item key='logout' onClick={this.onLogout}>
            <PoweroffOutlined style={{ marginRight:7 }}/>退出登录
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title='体验中心'>
          <Menu.Item key='ai_experience' onClick={this.aiExperience}>
            AI体验中心
          </Menu.Item>
          <Menu.Item key='resource_experience' onClick={this.resourceExperience}>
            资源体验中心
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title='后台管理'>
          {
            this.getMenuNodes(menuList)
          }
        </Menu.ItemGroup>
      </Menu>
    );
    return (
      <Layout className='bg-white overflow-hidden h-screen'>
        <Header style={{position: 'fixed',zIndex: 100, width: '100%', height: '66px'}} className="home-header px-0 bg-white border-b border-gray-300">
          <div className='2xl:container mx-auto flex items-center'>
            {/* <div className='flex items-center space-x-8'> */}
            <Link to='/'>
              <img src={Nettrix} alt='logo' className='h-12 mt-3' />
            </Link>
              {/* <AppHeader 
                user_role_id={user_role_id}
                roles={roles}
              /> */}
            {/* </div> */}
            <Dropdown overlay={menu} trigger={['hover']} placement="bottomLeft" className='user-container'>
              <img src={MyAccount} alt='myAccount' className='ml-auto w-9 h-9 mt-3 mr-2.5'/>
              {/* <UserOutlined className="text-xl ml-auto mr-4"/> */}
            </Dropdown>
          </div>
        </Header>
        <Content style={{marginTop: 66, flexGrow: 1, width: '100%'}} className='overflow-y-auto' id='gpufz_content'>
          <AppContent/>
        </Content>
        <Footer style={{textAlign: 'center', backgroundColor: 'white'}} className='border-t border-gray-300'>
          <div className=" flex flex-col flex-none space-y-2 items-center justify-center w-full text-gray-800 text-sm">
            <p>
              © 2019 - 2024 宁畅信息产业（北京）有限公司  保留所有权利
            </p>
            <p>
              Powered by 测试开发组
            </p>
          </div>
        </Footer>
        <EditInfoModal toggleVisible={this.toggleInfoVisible} visible={infoVisible} />
        <EditPasswordModal toggleVisible={this.togglePasswordVisible} visible={passwordVisible} />
      </Layout>
    )
  }
}
