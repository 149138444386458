const TOKEN = 'token'

export function isAuthenticated() { 
  return localStorage.getItem(TOKEN)
}

export function authenticateSuccess(token){
  return localStorage.setItem(TOKEN, token)
}

export function logout() {  
  return localStorage.removeItem(TOKEN)
}

export function unauthorized() {
  localStorage.removeItem(TOKEN)
  window.location.href='/#/sign/login'
}
