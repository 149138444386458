/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-lone-blocks */
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Card, Button, Modal } from 'antd';
import axios from 'axios';
import X620G40 from '@/assets/img/X620G40.png';
import X640G40 from '@/assets/img/X640G40.png';
import X660G45 from '@/assets/img/X660G45.png';
import X640G50 from '@/assets/img/X640G50.png';
import X680G55 from '@/assets/img/X680G55.png';
import Solution from '@/assets/img/solution.png'
import aiexperiencecenter from '@/assets/img/ai_experience_center.jpg'
import Hls from  'hls.js'
import IconFont from '@/components/IconFont';
import nvLogo from '@/assets/img/nv-logo.png';
import Nettrix from '@assets/img/nettrix_white.jpg'
import Resource1 from '@assets/img/resource1.png'
import Carousel from "react-elastic-carousel";
import { unauthorized } from '@/utils/session';
import './index.less';

function createMarkup(html){
  return { __html: html }
}
export default class NoLoginHome extends Component {
  state = {
    articles: [],
    k8snodes:[],
    url:'',
    isActive: [false,false,false,false,false],
    collections: [
      {
        id: '1',
        title: '运行DeepStream流分析工具包',
        target: 'AI 从业者',
        product: 'NVIDIA AI Enterprise',
        technical: 'NVIDIA DeepStream',
        mainProduct: 'X660 G45服务器，X640 G40服务器，X620 G40服务器',
        introduction: 'DeepStream是 NVIDIA 推出的针对智能视频分析应用的流分析工具包，DeepStream SDK 可以作为许多视频分析解决方案的基础层，让用户更专注于构建神经网络任务。',
        
      },
      {
        id: '2',
        title: '训练和部署AI聊天机器人',
        target: 'AI 从业者',
        product: 'NVIDIA AI Enterprise',
        technical: 'Tensorflow，TensorRT',
        mainProduct: 'X660 G45服务器，X640 G40服务器，X620 G40服务器',
        introduction: 'Chatbot是以智能对话系统为核心，应用于客服/营销/企业信息服务等多方场景的产品，它以文本、语音和多模态的产品形式，辅助或替代人工对话，赋能对话全流程为企业减少人力成本、提高工作效率。该案例可以帮助客户从文章的知识库中快速获得问题的答案，改善客户的问答体验。'
      },
      {
        id: '3',
        title: '使用RAPIDS加速库',
        target: '数据科学家，数据⼯程师',
        product: 'NVIDIA AI Enterprise',
        technical: 'NVIDIA RAPIDS',
        mainProduct: 'X660 G45服务器，X640 G40服务器，X620 G40服务器',
        introduction: 'RAPIDS是 NVIDIA 针对数据科学和机器学习推出的一套开源GPU加速库，基于CURD-X AI打造，使用RAPIDS加速库可以实现从数据准备、模型训练到预测整个到端到端流程得到GPU的加速支持，大大提升任务的执行效率。',
      },
      {
        id: '4',
        title: '训练用于在线产品图像分类的AI模型',
        target: 'AI 从业者',
        product: 'NVIDIA AI Enterprise',
        technical: 'Triton推理服务器，Tensorflow',
        mainProduct: 'X660 G45服务器，X640 G40服务器，X620 G40服务器',
        introduction: '通过编写客户端应用程序与Triton推理服务器进行通信，根据使用场景配置Triton推理服务器使用的模型，进而对输入的图片数据进行分类操作。',
      },
      {
        id: '5',
        title: '自然语言处理 Bert 训练性能演示',
        target: 'AI 从业者',
        product: 'NVIDIA NGC',
        technical: 'PyTorch，BERT，Kubernetes',
        mainProduct: 'X660 G45服务器，X640 G40服务器',
        introduction: '该案例根据一段文字中不同单词之间的关系理解文字，并支持回答问题、解释句子等语言相关用例，展示了自然语言处理的训练性能。'
      },
      {
        id: '6',
        title: '目标检测 SSD 训练性能演示',
        target: 'AI 从业者',
        product: 'NVIDIA NGC',
        technical: 'PyTorch，SSD，Kubernetes',
        mainProduct: 'X660 G45服务器，X640 G40服务器',
        introduction: '该案例检测图像中出现需要关注的不同目标，并为每个目标分别标识像素掩码，展示了目标检测的训练性能。',
      },
      {
        id: '7',
        title: '推荐系统 DLRM 训练性能演示',
        target: 'AI 从业者',
        product: 'NVIDIA NGC',
        technical: 'HugeCTR, DLRM，Kubernetes',
        mainProduct: 'X660 G45服务器，X640 G40服务器',
        introduction: '该案例通过了解用户与服务项目之间的交互情况，在社交媒体或电子商务网站等面向用户的服务中提供个性化结果，展示了推荐系统的训练性能。',
      },
      {
        id: '8',
        title: '图像分类 ResNet 训练性能演示',
        target: 'AI 从业者',
        product: 'NVIDIA NGC',
        technical: 'MXNet, ResNet，Kubernetes',
        mainProduct: 'X660 G45服务器，X640 G40服务器',
        introduction: '该案例应用于计算机视觉问题，展示了图像分类的训练性能。',
      },
    ],
    currentCollection: {},
    experienceUrl: [],
    registerModal: true,
    detailModal: false,
    projectDesc: ''
  }
  /** 获取首页文章 */
  getHomeArticle = async ()=>{
    await axios({
      method: 'get',
      url: `/api1/api/v1/admin/article_positions/home`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if(response.status===200){
        const res = response.data
        this.setState({
          articles: res.articles.reverse()
        })
      }
    })
    .catch(error => {
      if(error.response){
        const res = error.response
        if(res.status===400){
          console.log('服务器验证失败');
        }else if(res.status===401){
          console.log('没有权限');
          unauthorized()
        }else if(res.status===500){
          console.log('Internal server error');
        }
      }
    })
  }
  /** 获取首页视频 */
  getHomeVideo = async ()=>{
    await axios({
      method: 'get',
      url: `/api1/api/v1/admin/video_positions/${'首页'}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if(response.status===200){
        const res = response.data
        this.setState({
          videos: res.videos[0]
        })
        this.getVideoInfo(res.videos[0].video_id)
      }
    })
    .catch(error => {
      if(error.response){
        const res = error.response
        if(res.status===400){
          console.log('服务器验证失败');
        }else if(res.status===401){
          console.log('没有权限');
          unauthorized();
        }else if(res.status===500){
          console.log('Internal server error');
        }
      }
    })
  }
  /** 获取首页Project Desc */
  getHomeProjectDesc = async()=>{
    await axios({
      method: 'get',
      url: '/api1/api/v1/service/desc/home_project_desc/',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if(response.status===200){
        const res = response.data
        this.setState({
          projectDesc: res
        })
      }
    })
    .catch(error => {
      if(error.response){
        const res = error.response
        if(res.status===400){
          console.log('服务器验证失败');
        }else if(res.status===401){
          console.log('没有权限');
          unauthorized();
        }else if(res.status===500){
          console.log('Internal server error');
        }
      }
    })
  }

  getVideoInfo = async (video_id) => {
    await axios({
      method: 'get',
      url: `/api1/api/v1/videos/stream/${video_id}`,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob'
    })
    .then(response => {
      if(response.status===200){
        const res = response.data
        const blob = new Blob([res])
        const objUrl = URL.createObjectURL(blob)
        this.setState({
          url: objUrl
        })
        this.playVideo();
      }
    })
    .catch(error => {
      console.log(error)
      if(error.response){
        const res = error.response
        if(res.status===400){
          console.log('服务器验证失败');
        }else if(res.status===401){
          console.log('没有权限');
        }else if(res.status===500){
          console.log('Internal server error');
        }
      }
    })
  }

  UNSAFE_componentWillMount() {
    this.getHomeArticle();
    // this.getVideoInfo();
    this.getHomeVideo();
    // this.playVideo()
    this.getHomeProjectDesc()
  }
  playVideo = ()=>{
    const video = document.getElementById('m3u8video');
    if(!video) return false
    const videoSrc = video.src
    if(video && video.canPlayType('application/vnd.apple.mpegurl')){
      video.src = videoSrc;
    }else if(Hls.isSupported()){
      const hls = new Hls();
      hls.loadSource(videoSrc)
      hls.attachMedia(video)
    }
  }

  openRegister = ()=>{
    this.setState({
      registerModal: !this.state.registerModal
    })
  } 

  // 打开了解详情的Modal
  openDetailModal = (item)=>{
    this.setState({
      detailModal: true,
      currentCollection: item
    })
  }

  // 关闭了解详情的Modal
  handleDetailCancel = ()=>{
    this.setState({
      detailModal: false
    })
  }

  constructor(props) {
    super(props)
    this.breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 640, itemsToShow: 1 },
      { width: 768, itemsToShow: 2 },
      { width: 800, itemsToShow: 3 },
      { width: 1280, itemsToShow: 3 },
      { width: 1440, itemsToShow: 3 }
    ]
  }
  
  gotoResource = ()=>{
    localStorage.setItem('needToken', false)
    this.props.history.push({
      pathname: '/resource',
      query: {
        needToken: false
      }
    });
  }
  gotoAIInfo = ()=>{
    this.props.history.push({
      pathname: '/aiexperience'
    })
  }

  render() {
    const { articles, url, projectDesc, registerModal, detailModal, currentCollection } = this.state
    const img = [X680G55,X640G50,X660G45,X620G40,X640G40]
    return (
      <div className='flex flex-col bg-white nologinhome overflow-x-hidden'>
        {/* <Alert
          message="系统通知"
          description="亲爱的用户，感谢您对宁畅的关注，系统当前维护中，体验活动于4月24日暂停一天，由此带来的不便敬请谅解。"
          type="info"
          showIcon
          closable
          className='resourceAlert'
          style={{
            margin: 8,
            marginBottom: 0,
          }}
        /> */}
        <div style={{position: 'fixed',zIndex: 1, width: '100%', height: '66px'}} className="bg-white border-b border-gray-300">
          <div className='2xl:container mx-auto px-1 2xl:px-0'>
            <Link to='/'>
              <img src={Nettrix} alt='logo' className='h-12 mt-2.5' />
            </Link>
          </div>
        </div>
        <div className='2xl:container mx-auto py-2 overflow-x-hidden'>
          <div className='mt-16 2xl:mt-20 banner'>
            {/* 内网播放 */}
            <video id='m3u8video' controls src={url} muted='muted' autoPlay className='home-video p-2 2xl:p-0' loop='loop'></video>
          </div>
          {/* 宁畅算力栈解决方案介绍 */}
          <div className='mt-20 py-20 px-2 lg:px-32 bg-gray-100 group'>
            <div className='flex flex-col space-y-5 mb-4 md:mb-14'>
              <h1 className='text-2xl sm:text-4xl font-semibold text-black mx-auto group-hover:tracking-wide group-hover:underline transition-all duration-300 decoration-sky-500 decoration-dashed decoration-2 underline-offset-8'>宁畅算力栈解决方案介绍</h1>
            </div>
            <div className='flex flex-col gap-8 md:flex md:flex-row items-center justify-between md:gap-24 w-full'>
              <img src={Solution} alt='resource' className='w-full md:w-1/2'/>
              <div className='flex flex-col gap-4'>
                {
                  projectDesc?.split('\n')?.map((line,index)=>(
                    <div className='text-base sm:text-lg font-semibold leading-10 text-justify' key={index}>
                      {line}
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          {/* AI 应用体验中心 */}
          <div className='bg-white mt-12 mb-20 py-16 px-2 lg:px-32 group'>
            <div className='flex flex-col space-y-5 mb-4 md:mb-14'>
              <h1 className='text-2xl sm:text-4xl font-semibold text-black mx-auto group-hover:tracking-wide group-hover:underline transition-all duration-300 decoration-sky-500 decoration-dashed decoration-2 underline-offset-8'>AI 应用体验中心</h1>
              <h2 className='text-lg sm:text-xl font-semibold text-gray-800 mx-auto max-w-4xl text-center'>AI 应用体验中心是基于宁畅算力栈的人工智能学习与实训平台，提供计算运行环境、免费GPU算力、大量调优算法和数据，帮助开发者快速创建和部署模型。</h2>
            </div>
            <div className='flex flex-col gap-8 smd:flex md:flex-row items-start justify-between md:gap-24 w-full'>
              <div>
                <div className='text-base sm:text-lg font-semibold leading-10 mb-4 text-justify'>AI 应用体验中心致力于为用户提供全方位的人工智能技术体验，涵盖AIGC、ChatGPT、LLAMA等领先技术。通过这一综合平台，用户可以深度体验图形计算、自然语言处理和多模态学习等领域的创新应用，探索人工智能技术在图像处理、对话交互和跨模态信息处理方面的无限潜能。</div>
                <button type="button" 
                  className="flex items-center gap-3 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-none text-sm px-6 py-2.5 focus:outline-none"
                  onClick={this.gotoAIInfo}
                >
                  <div className='font-semibold'>前往体验</div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7"/></svg>
                </button>
              </div>
              <img src={aiexperiencecenter} alt='resource' className='w-full md:w-1/2'/>
            </div>
          </div>
          {/* 图形计算体验中心 */}
          <div className='py-20 px-2 lg:px-32 bg-gray-100 group'>
            <div className='flex flex-col mb-4 space-y-5 md:mb-14'>
              <h1 className='text-2xl sm:text-4xl font-semibold text-black mx-auto group-hover:tracking-wide group-hover:underline transition-all duration-300 decoration-sky-500 decoration-dashed decoration-2 underline-offset-8'>图形计算体验中心</h1>
              <h2 className='text-lg sm:text-xl font-semibold text-gray-800 mx-auto'>触手可得的NGC容器服务，在图形计算集群中体验AI带来的颠覆性、创造性体验。</h2>
            </div>
            <div className='flex flex-col gap-8 md:flex md:flex-row items-center justify-between md:gap-24 w-full'>
              <img src={Resource1} alt='resource' className='w-full md:w-1/2'/>
              <div>
                <div className='text-justify text-base sm:text-lg font-semibold leading-10 mb-4'>提供千卡级的高性能计算资源以及企业级AI开发平台，旨在充分满足机器学习训练的严苛需求。赋能开发者轻松创建、灵活调度和全面监控各类机器学习任务。涵盖数据预处理、模型训练及性能评估等关键环节。为新建数据中心的客户提供专业的集群硬件与软件优化建议，确保集群在复杂的业务类型下能够在最佳状态下高效运行。</div>
                <button type="button" 
                  className="flex items-center gap-3 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-none text-sm px-6 py-2.5 focus:outline-none"
                  onClick={this.gotoResource}
                >
                  <div className='font-semibold'>前往体验</div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7"/></svg>
                </button>
              </div>
            </div>
          </div>
          {/* 人工智能服务产品栈 */}
          <div className='mt-12 py-20 px-2 md:px-12 bg-white group'>
            <div className='flex flex-col space-y-2 md:space-y-5 mb-14'>
              <h1 className='text-2xl sm:text-4xl font-semibold text-black mx-auto group-hover:tracking-wide group-hover:underline transition-all duration-300 decoration-sky-500 decoration-dashed decoration-2 underline-offset-8'>人工智能服务器产品栈</h1>
              <h2 className='text-lg sm:text-xl font-semibold text-gray-800 mx-auto'>为您的AI产品构建最坚实、可靠的计算资源平台，从轻量到集群，提供一系列完整的解决方案。</h2>
            </div>
            <div>
              {
                articles.length > 0 ?
                <Carousel
                  breakPoints={this.breakPoints}
                >
                  {
                    articles.map((item, index)=>(
                    item.status 
                      ?
                        <div className='shadow-md hover:shadow-xl mb-4' key={index}>
                          <Card 
                            cover={
                              <img alt='example' className='h-[204px] object-fill' src={img[index]} style={{borderRadius: '6px 6px 0 0', padding: 0.5}}/>
                            }
                            className='rounded-md border-gray-300 max-w-sm h-[500px] '
                            style={{borderRadius: 6}}
                            key={item.article_id}
                          >
                            <div>
                              <h1 className='text-xl font-semibold text-blue-400 mb-4'>{item.title}</h1>
                              <div dangerouslySetInnerHTML={createMarkup(item.article_content)} className='braft-output-content text-sm text-justify leading-7'></div>
                            </div>
                          </Card>
                        </div>
                      : null
                    ))
                  }
                </Carousel>
                : null
              }
            </div> 
          </div>
          {/* NVIDIA 合作伙伴 */}
          <div className='my-16 py-20 px-2 md:px-12 bg-gray-100'>
            <div className='flex flex-col mb-6 space-y-5'>
              <h1 className='text-2xl sm:text-3xl font-semibold text-black mx-auto'>NVIDIA 合作伙伴</h1>
              <h2 className='text-lg font-semibold text-gray-800 mx-auto'>深入了解我们与 NVIDIA 的卓越合作，共同推动 AI 技术的发展和应用。</h2>
            </div>
            <div className='flex items-center justify-center'>
              <button type="button" className="w-[240px] flex items-center gap-3 text-white bg-[#76B900] hover:bg-[#5A8D01] focus:ring-4 focus:ring-green-300 font-medium rounded-none text-sm px-6 py-2.5 focus:outline-none justify-between">
                <a
                  href='https://www.nvidia.cn/'
                  target='_blank' 
                  className='font-semibold text-white'
                >
                  NVIDIA中国官方网站
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7"/></svg>
              </button>
            </div>
          </div>
          <button className='buttonAnchor bg-gray-100 shadow-2xl' onClick={this.openRegister}>
            {
              registerModal 
              ? 
              <IconFont type='icon-close5' className='text-4xl flex items-center justify-center'/> 
              : 
              <IconFont type='icon-zhucedenglu' className='text-3xl flex items-center justify-center'/>
            }
          </button>
          {
            registerModal
            ?  <div className='registerAnchor bg-gray-100 shadow-2xl pt-2 flex flex-col space-y-4'>
                  <div className='text-base text-gray-900 w-full px-5'>
                    登录后可以使用以下内容:
                  </div>
                  <ul className='text-sm flex items-center justify-between mx-4'>
                    <li><IconFont type='icon-shenduxuexi' className='text-2xl mr-2 text-gray-500' />DeepStream</li>
                    <li><IconFont type='icon-rengongzhineng' className='text-2xl mr-2 text-gray-500' />ChatBox</li>  
                  </ul>
                  <ul className='text-sm flex items-center justify-between mx-4'>
                    <li><IconFont type='icon-jiqixuexi' className='text-2xl mr-2 text-gray-500' />Rapids</li>
                    <li className='mr-4'><IconFont type='icon-icon-24px2x' className='text-2xl mr-2 text-gray-500' />Triton</li>
                  </ul>
                  <div className='text-center px-2'>
                    <Button type='primary' className='w-11/12 h-10 mb-1 bg-blue-500' style={{ borderColor: '#bebebe' }}>
                      <Link to='/sign/requestRegister'>免费注册体验</Link>
                    </Button>
                    <Button type='link'>
                      <Link to='/sign/login' className='flex'>
                        <p className='text-gray-500'>已注册？</p>点我登录
                      </Link>
                    </Button>
                  </div>
              </div>
            : <></>
          }
          {/* 了解详情的Modal */}
          <Modal
            visible={detailModal}
            title={
              <div className='flex justify-between mx-6 mt-8'>
                <div className='text-2xl font-medium'>{currentCollection.title}</div>
                <div>
                  <img src={nvLogo} alt='nvlogo' className='h-8 w-44'/>
                </div>
              </div>
            }
            onCancel={this.handleDetailCancel}
            width={900}
            className='h-1/2'
            footer={null}
          >
          <div className='m-6 mb-8 text-lg'>
              <div className='space-y-4 mb-8'>
                <p className='font-semibold'>模型所涉及的产品：</p>
                <p>{currentCollection.mainProduct}</p>
              </div>
              <div  className='space-y-4'>
                <p className='font-semibold'>模型简介：</p>
                <p className='text-justify'>{currentCollection.introduction}</p>
              </div>
          </div>
          </Modal>
        </div>
        <div className='bg-white border-t border-gray-300 py-6'>
          <div className="flex flex-col flex-none space-y-2 items-center justify-center w-full text-gray-800 text-sm">
            <p>
              © 2019 - 2024 宁畅信息产业（北京）有限公司  保留所有权利
            </p>
            <p>
              Powered by 测试开发组
            </p>
          </div>
        </div>
      </div>
    )
  }
}
