import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LoadableComponent from '@/utils/LoadableComponent';
const Home = LoadableComponent(()=>import('@/pages/home'))
const AIExperience = LoadableComponent(()=>import('@/pages/aiExperience'))
const Resource = LoadableComponent(()=>import('@/pages/resource'))
const Grafana = LoadableComponent(()=>import('@/pages/resource/grafana'))
const ChatGPT = LoadableComponent(()=>import('@/pages/chatgpt'))
const Management = LoadableComponent(()=>import('@/pages/management'))
const ExperienceModel = LoadableComponent(()=>import('@/pages/aiExperience/model'))
export default class AppContent extends Component {
  render() {
    return (
      <Switch>
        <Redirect from='/' exact to='/home'/>
        <Route path='/home' component={Home}/>
        <Route path='/aiexperiencemodel/:modelId' component={ExperienceModel} />
        <Route path='/aiexperience' component={AIExperience}/>
        <Route path='/resource/gpuinfo/:nodename' component={Grafana} />
        <Route path='/resource' component={Resource}/>
        <Route path='/chatgpt' component={ChatGPT}/>
        <Route path='/management' component={Management}/>
      </Switch>
    )
  }
}
