import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LoadableComponent from '@/utils/LoadableComponent';
import './index.less';


const LoginForm = LoadableComponent(()=>import('@/pages/login/loginForm'))
const RegisterForm = LoadableComponent(()=>import('@/pages/register/registerForm'))
const RequestRegisterForm = LoadableComponent(()=>import('@/pages/requestRegister/requestRegisterForm'))
const ForgetPassword = LoadableComponent(()=>import('@/pages/login/forgetPassword'))

export default class Login extends Component {
  render() {
    return (
      <Switch>
        <Redirect exact path='/sign' to='/sign/login' />
        <Route path='/sign/login' component={LoginForm}/>
        <Route path='/sign/register' component={RegisterForm}/>
        <Route path='/sign/requestRegister' component={RequestRegisterForm}/>
        <Route path='/sign/forgetPassword' component={ForgetPassword}/>
      </Switch>
    )
  }
}
