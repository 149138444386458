import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import menuList from '@/config/menuConfig';

import './index.less';
class AppHeader extends Component {

  hasAuth = (item) => {
    const { key, isPublic } = item
    const { user_role_id, roles } = this.props;
    if(roles.length!==0){
      const userAuth = roles.filter((items)=>{
        return user_role_id===items.role_id
      })
      if(userAuth.length!==0){
        if(isPublic || userAuth[0].menus.indexOf(key)!==-1){
          return true
        }else if(item.children){
          return !!item.children.find(child => userAuth[0].menus.indexOf(child.key)!==-1)
        }
      }
    }
    return false
  }
  getMenuNodes = (menuList)=>{
    const path = this.props.location.pathname
    return menuList.reduce((pre,item)=>{
      if(this.hasAuth(item)){
        if(!item.children){
          pre.push((
            <Menu.Item key={item.key}>
              <Link to={item.key}>
                <span>{item.title}</span>
              </Link>
            </Menu.Item>
          ))
        }else {
          const cItem = item.children.find(cItem => path.indexOf(cItem.key)===0)
          if(cItem){
            this.openKey = item.key
          }
          pre.push((
            <Menu.SubMenu
              key={item.key}
              title={
                <span>{item.title}</span>
              }
            >
              {
                this.getMenuNodes(item.children)
              }
            </Menu.SubMenu>
          ))
        }
      }
      return pre
    },[])
  }
  
  render() {
    let path = this.props.location.pathname
    const openKey = this.openKey
    return (
      <Menu
        selectedKeys={[path]}
        defaultOpenKeys={[openKey]}
        mode="horizontal"
        className="inline-block font-semibold"
      >
        {
          this.getMenuNodes(menuList)
        }
      </Menu>
    )
  }
}
export default withRouter(AppHeader)